<template>
  <z-card class="main-card">
    <template #content>
      <div
        v-if="personalizationWelcome.useDefault !== true"
        data-cy="welcome-card"
        class="main-content"
      >
        <h3
          class="text-center my-2 px-4 mx-6 body-bold"
          :style="
            $vuetify.breakpoint.smAndDown ? 'white-space: break-spaces' : ''
          "
          v-html="personalizationWelcome.title[currentLang]"
        ></h3>

        <div class="card-scrollbar my-3">
          <section class="content-card">
            <div class="checklist-img">
              <img
                class="image-welcome mx-2 mb-2"
                :src="`${personalizationWelcome.imageUrl}`"
              />
            </div>
            <p
              class="body-text text-center pl-2 pr-4 text-card"
              v-html="personalizationWelcome.text[currentLang]"
            ></p>
          </section>
        </div>
        <div class="card-btn-container">
          <z-btn
            class="btn-proceed my-2"
            primary
            :text="$t('welcome.proceed')"
            @click="$emit('click', 'welcome-menu')"
          />
          <language-select
            v-if="$vuetify.breakpoint.smAndDown"
            class="justify-center mt-2"
          />
        </div>
      </div>
      <div v-else data-cy="welcome-card" class="main-content">
        <h3 class="text-center my-2 px-6 mx-4 body-bold">
          {{ $t("welcome.welcome_menu_title") }} {{ hotel.nomeFantasia }}
        </h3>

        <div class="card-scrollbar my-3">
          <section class="content-card">
            <div class="checklist-img">
              <illustration-welcome
                class="image-welcome mx-2 mb-2"
              ></illustration-welcome>
            </div>
            <p class="body-text text-center pl-2 pr-4 text-card">
              {{ $t("welcome.welcome_menu_text") }}
            </p>
          </section>
        </div>
        <div class="card-btn-container">
          <z-btn
            class="btn-proceed my-2"
            primary
            :text="$t('welcome.proceed')"
            @click="$emit('click', 'welcome-menu')"
          />
          <language-select
            v-if="$vuetify.breakpoint.smAndDown"
            class="justify-center mt-2"
          />
        </div>
      </div>
    </template>
  </z-card>
</template>

<script>
export default {
  name: "WelcomeCard",
  props: {
    currentLang: {
      type: String,
      default: "Pt",
    },
    isWelcomeEnabled: {
      type: Boolean,
      default: true,
    },
    titleCard: {
      type: String,
      default: "Seja Bem-Vindo ao Hotel Zoox",
    },
    imageCard: {
      type: String,
      default: "",
    },
    textCard: {
      type: String,
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices vel nunc non fringilla. Suspendisse potenti. Suspendisse sed facilisis ligula. Vivamus mattis enim in euismod sodales. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque consequat mi id lectus aliquet viverra. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla a posuere magna. Nulla eleifend leo non diam dapibus, et ultricies arcu tempus. Sed egestas, est eu condimentum pretium, felis quam viverra purus, sit amet mattis erat nisl vitae quam. Suspendisse condimentum elit tristique arcu venenatis aliquet. Duis egestas lorem sed mauris pellentesque, feugiat elementum lectus posuere. Cras auctor euismod tempus. Morbi lacinia, augue ac sollicitudin vehicula, diam odio placerat arcu, ac vulputate orci purus et odio.",
    },
    personalizationWelcome: {
      type: Object,
      default: () => {},
    },
    hotel: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style module="classes" lang="scss">
.div {
  margin: 10px auto;
  p {
    text-align: center;
    font-size: 0.9375rem;
    line-height: 120%;
  }
}
</style>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;
}

.card-scrollbar {
  max-height: 50vh;
  overflow-y: scroll;
}

.main-card {
  width: 100% !important;
  align-self: center;
}

.card-btn-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
}

.btn-proceed {
  width: 100%;
}
.image-welcome {
  max-width: 180px;
}

@media screen and (min-width: 960px) {
  .main-card {
    width: 960px !important;
  }
}

//desktop
@media screen and (min-width: 786px) {
  .card-btn-container {
    text-align: center;
    margin-top: 16px;
  }

  .btn-proceed {
    width: 140px !important;
    height: 40px !important;
  }

  .content-card {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .main-card {
    width: 960px;
  }

  .card-scrollbar {
    height: auto;
    text-align: center;
  }

  .image-welcome {
    max-width: 180px;
  }

  .text-card {
    text-align: justify !important;
  }

  .card-scrollbar {
    overflow-y: hidden;
  }
  .checklist-img {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
