import { render, staticRenderFns } from "./WelcomeCard.vue?vue&type=template&id=de9965a2&scoped=true&"
import script from "./WelcomeCard.vue?vue&type=script&lang=js&"
export * from "./WelcomeCard.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeCard.vue?vue&type=style&index=0&id=de9965a2&prod&module=classes&lang=scss&"
import style1 from "./WelcomeCard.vue?vue&type=style&index=1&id=de9965a2&prod&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["classes"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "de9965a2",
  null
  
)

export default component.exports