<template>
  <z-card-modal
    class="find-result-modal"
    persistent
    :title="$t('welcome.expired_pin_title')"
    :opened="opened"
    :width="$vuetify.breakpoint.xs ? '100%' : '400px'"
  >
    <template #content>
      <main class="find-result-modal">
        <div class="body-text mt-2">
          <p class="mb-1">
            {{ $t("welcome.expired_pin_text1") }}
          </p>
          <p>
            {{ $t("welcome.expired_pin_text2") }}
          </p>
        </div>
        <z-btn
          class="ok-btn"
          primary
          :text="$t('welcome.expired_pin_btn')"
          @click="$emit('click:ok')"
        />
      </main>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    founded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.find-result-modal {
  max-width: 400px;
  display: grid;
  gap: 15px;
}

.section {
  display: grid;
  grid-auto-flow: column;
  gap: 15px;
}

.ok-btn {
  justify-self: end;
  width: 155px;
}

@media screen and (max-width: 960px) {
  .ok-btn {
    width: 100%;
  }
}
</style>
