<template>
  <div class="d-flex align-center justify-center">
    <welcome-card
      v-if="$vuetify.breakpoint.smAndDown && onboarding === true"
      :current-lang="currentLang.charAt(0).toUpperCase() + currentLang.slice(1)"
      :hotel="hotel"
      :personalization-welcome="stepsConfig[0].content"
      @click="$router.push({ name: 'onboarding' })"
    />
    <welcome-card
      v-else
      :hotel="hotel"
      :personalization-welcome="stepsConfig[0].content"
      :current-lang="currentLang.charAt(0).toUpperCase() + currentLang.slice(1)"
      @click="$store.dispatch('flow/reservation/next')"
    />
    <expired-pin-modal :opened="isExpiredPin" @click:ok="onConfirmExpiredPin" />
    <expired-reservation-modal
      :opened="isExpiredReservationModalOpen"
      :res-status="reservation.resStatus"
      @click:close="$router.push({ name: 'welcome-menu' })"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue"
import {
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import ExpiredReservationModal from "@/components/Alert/AlertTypes/ExpiredReservationModal.vue"

import ExpiredPinModal from "./components/modals/ExpiredPinModal.vue"
import WelcomeCard from "./components/WelcomeCard.vue"

export default {
  components: {
    WelcomeCard,
    ExpiredPinModal,
    ExpiredReservationModal,
  },
  props: {
    languages: {
      type: Array,
      default: () => [{ text: "Português", value: "pt", flag: "br" }],
    },
  },
  setup() {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    const { hotel } = useNamespacedState("company", ["hotel"])
    const { onboarding } = useNamespacedState("parameters", ["onboarding"])
    const { stepsConfig } = useNamespacedState("company", ["stepsConfig"])
    const isExpiredPin = ref(false)
    const isExpiredReservationModalOpen = ref(false)

    const { reservation } = useNamespacedState("reservation", ["reservation"])
    const { reservationIsInvalid, reservationIsExpired } = useNamespacedGetters(
      "reservation",
      ["reservationIsInvalid", "reservationIsExpired"]
    )

    onMounted(() => {
      const expiredPin = localStorage.getItem("expiredPin")
      if (expiredPin) {
        isExpiredPin.value = true
      }

      if (reservationIsExpired.value || reservationIsInvalid.value) {
        isExpiredReservationModalOpen.value = true
      }
    })

    const onConfirmExpiredPin = () => {
      localStorage.removeItem("expiredPin")
      isExpiredPin.value = false
    }

    return {
      hotel,
      onboarding,
      stepsConfig,
      isExpiredPin,
      currentLang,
      onConfirmExpiredPin,
      reservation,
      isExpiredReservationModalOpen,
    }
  },
}
</script>
